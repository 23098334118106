import ConceptTestRepository from "./ConceptTestRepository";
import UserRepository from "./UserRepository";

const repositories = {
  conceptTest: ConceptTestRepository,
  user: UserRepository,
};

export default {
  get: (name) => repositories[name],
};
