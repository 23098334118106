<template>
  <h1>Koncepttests</h1>
  <p class="small">Klik på en kolonne-overskrift for at sortere tabellen.</p>
  <table class="table table-sort table-arrows">
    <thead>
      <tr>
        <th>Id</th>
        <th>Navn</th>
        <th>Kategori</th>
        <th>Metode</th>
        <th>Periode</th>
        <th>N</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="conceptTest in this.tests"
        v-bind:key="conceptTest.conceptTestId"
      >
        <td>{{ conceptTest.conceptTestId }}</td>
        <td>{{ conceptTest.name }}</td>
        <td>
          <span class="badge bg-primary" v-if="conceptTest.productCategory == 1"
            >Hovedprodukt</span
          >
          <span
            class="badge bg-secondary"
            v-if="conceptTest.productCategory == 2"
            >Nicheprodukt</span
          >
        </td>
        <td>{{ conceptTest.analysisType }}</td>
        <td class="data-sort" :data-sort="toDate(conceptTest.date).getTime()">
          <span style="display: none">{{
            toDate(conceptTest.date).toISOString()
          }}</span>
          {{ conceptTest.period }}
        </td>
        <td>{{ conceptTest.targetGroup.nTotal }}</td>
        <td>
          <router-link
            :to="{ name: 'Results', params: { id: conceptTest.conceptTestId } }"
            class="nav-link"
          >
            Detaljer
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import tableSort from "table-sort-js/table-sort.js";

export default {
  name: "TestOverview",
  computed: {
    tests() {
      return this.$store.getters["conceptTests/getConceptTests"];
    },
  },
  methods: {
    toDate(strDate) {
      return new Date(strDate);
    },
  },
  mounted() {
    tableSort();
  },
};
</script>
