<template>
  <div class="filters mb-3 mt-1">
    <div class="dropdown">
      <button
        class="btn btn-outline-dark dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ selectedType.name }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-for="product in productTypes" v-bind:key="product.name">
          <a
            class="dropdown-item"
            href="#"
            @click="changeProductType(product.id)"
          >
            {{ product.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <highcharts :options="computedOptions" ref="highcharts"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

var drawnStuff = [];

const productTypes = [
  { name: "Alle produkter", id: 0 },
  { name: "Hovedprodukter", id: 1 },
  { name: "Nicheprodukter", id: 2 },
];

export default {
  name: "MagicBox",
  computed: {
    computedOptions() {
      var options = this.chartOptions;
      options.series =
        this.$store.getters["conceptTests/getConceptTestsForModel"];
      return options;
    },
  },
  methods: {
    changeProductType(id) {
      this.selectedType = this.productTypes[id];
      if (drawnStuff && drawnStuff.length > 0) {
        this.destroyMagicBoxes();
      }
      this.updateDisplayedValues(id);
      this.drawMagicBoxes(id);
    },
    destroyMagicBoxes() {
      drawnStuff.forEach((element) => {
        element.destroy();
      });
      drawnStuff = [];
    },
    updateDisplayedValues(typeId) {
      this.chartOptions.series.forEach((element) => {
        console.log(typeId);
        if (typeId == 0) {
          element.visible = true;
        } else if (typeId == element.category) {
          element.visible = true;
        } else {
          element.visible = false;
        }
      });
    },
    drawMagicBoxes(typeId) {
      if (typeId == 0) return;
      if (typeId == 1 || typeId == 2) {
        const chart = this.$refs.highcharts.chart,
          renderer = chart.renderer,
          height = chart.plotHeight,
          width = chart.plotWidth;

        const maxWidth = 70,
          maxHeight = 100,
          widthRel = width / maxWidth,
          heightRel = height / maxHeight;

        drawnStuff[0] = renderer
          .rect(chart.plotLeft, chart.plotTop, widthRel * 70, heightRel * 100)
          .attr({ fill: "#66A6A3" })
          .add();

        let benchmark = 20;
        if (typeId == 1) {
          benchmark = 25;
        }
        if (typeId == 2) {
          benchmark = 35;
        }

        drawnStuff[1] = renderer
          .rect(
            chart.plotLeft + widthRel * 20,
            chart.plotTop,
            widthRel * 50,
            heightRel * (100 - benchmark)
          )
          .attr({ fill: "#338885" })
          .add();

        drawnStuff[2] = renderer
          .rect(
            chart.plotLeft + widthRel * 40,
            chart.plotTop,
            widthRel * 30,
            heightRel * 40
          )
          .attr({ fill: "#006A66" })
          .add();
      }
    },
  },
  data() {
    return {
      chartRenderer: {},
      productTypes: productTypes,
      selectedType: productTypes[0],
      benchmark: 35,
      chartOptions: {
        chart: {
          height: 700,
          width: 900,
          type: "scatter",
          events: {},
        },
        title: {
          text: null,
        },
        plotOptions: {
          series: {
            marker: {
              radius: 1,
            },
          },
        },
        legend: {
          enabled: true,
          symbolHeight: 10,
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          title: { text: "UNIKHED" },
          labels: {
            format: "{value}%",
          },
        },
        xAxis: {
          min: 0,
          max: 70,
          tickInterval: 10,
          title: { text: "KØBSINTENTION" },
          labels: {
            format: "{value}%",
          },
        },
        tooltip: {
          formatter: function () {
            const string =
              "<b>" +
              this.series.name +
              "</b><br/>" +
              "<span style='font-size:0.8em;text-style:italic;'>Gennemført d. " +
              this.point.dateCompleted +
              " </span><br/>" +
              "Unikhed:&nbsp;" +
              this.y +
              "%<br/>Købsintention:&nbsp;" +
              this.x +
              "%";
            return string;
          },
        },
        series: [],
      },
    };
  },
  components: {
    highcharts: Chart,
    Highcharts,
  },
};
</script>
