import Repositories from "@/data/RepositoriesFactory";
const userRepository = Repositories.get("user");

export default {
  name: "auth",
  namespaced: true,
  state: () => ({
    user: {},
    token: null,
  }),
  getters: {
    getToken: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.token !== null;
    },
    isAdmin: (state) => {
      if (state.user) {
        return state.user.isAdmin;
      }
      return false;
    },
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.token = user.token;
      state.user = user;
    },
    setLoggedOut(state) {
      state.user = {};
      state.token = null;
    },
  },
  actions: {
    async authenticate({ commit }, form) {
      const response = await userRepository
        .login(form.username, form.password)
        .catch((error) => {
          return error.response;
        });

      if (response.status === 200) {
        commit("setLoggedInUser", response.data);
      }
      return response;
    },
    async fetchLoggedInUser({ commit }, token) {
      const response = await userRepository.loginbytoken(token);
      if (response.status === 200) {
        commit("setLoggedInUser", response.data);
      }
    },
    setLoggedOut({ commit }) {
      commit("setLoggedOut");
    },
  },
};
