<script>
import store from "@/store";

export default {
  name: "Logout",
  beforeRouteEnter(to, from, next) {
    store.dispatch("auth/setLoggedOut");
    next("/login");
  },
};
</script>
