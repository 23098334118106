<template>
  <h1>Brugere</h1>
  <p>
    <router-link :to="{ name: 'CreateUser', params: { id: null } }"
      >Opret ny bruger</router-link
    >
  </p>
  <div>
    <user-list v-if="this.users" v-bind:users="this.users"></user-list>
  </div>
</template>

<script>
import UserList from "@/components/users/UserList.vue";

export default {
  name: "Users",
  components: { UserList },
  computed: {
    users() {
      return this.$store.getters["users/getUsers"];
    },
  },
};
</script>
