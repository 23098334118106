<template>
  <div>
    <table class="table">
      <thead>
        <th>Name</th>
        <th>Username</th>
        <th>Administrator</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-bind:key="user.id" v-for="user in users">
          <td>{{ user.firstName }} {{ user.lastName }}</td>
          <td>{{ user.userName }}</td>
          <td><span v-if="user.isAdmin">Ja</span></td>
          <td>
            <router-link
              class="btn btn-brand"
              :to="{ name: 'EditUser', params: { id: user.id } }"
              >Rediger</router-link
            >
            <button
              v-if="user.id != this.loggedInUser.id"
              class="btn btn-danger"
              v-on:click="this.delete(user.id)"
            >
              Slet
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "UserList",
  props: {
    users: Array,
  },
  computed: {
    loggedInUser() {
      return this.$store.getters["auth/getUser"];
    },
  },
  methods: {
    async delete(userId) {
      if (confirm("Er du sikker på at du vil slette brugeren?")) {
        await this.$store.dispatch("users/deleteUser", userId);
      }
    },
  },
};
</script>
