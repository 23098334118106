<template>
  <h1>Opret bruger</h1>
  <create-user-form></create-user-form>
</template>

<script>
//import { useRoute } from "vue-router";
import CreateUserForm from "@/components/users/CreateUserForm.vue";

export default {
  name: "User",
  components: { CreateUserForm },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    user() {
      if (this.userId) {
        return this.$store.getters["users/getUserById"](this.userId);
      }
      return null;
    },
  },
};
</script>
