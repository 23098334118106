<template>
  <h1>Testresultater</h1>
  <div class="row">
    <div class="col-8"><magic-box></magic-box></div>
    <div class="col-4"><info-box></info-box></div>
  </div>
</template>

<script>
// @ is an alias to /src
import InfoBox from "@/components/comparer/InfoBox.vue";
import MagicBox from "@/components/comparer/MagicBox.vue";

export default {
  name: "Comparer",
  components: {
    InfoBox,
    MagicBox,
  },
};
</script>
