<template>
  <div class="card text-white bg-secondary mb-3">
    <div class="card-header">Beskrivelse</div>
    <div class="card-body">
      <h5 class="card-title">MAGIC BOX</h5>
      <p class="card-text">
        Sammenligneren viser dig resultater på tværs af koncepttests, målt på to
        parametre:
      </p>
      <ul>
        <li><b>Unikhed:</b> I hvor høj grad skiller produktet sig ud</li>
        <li>
          <b>Købsintention:</b> Hvor sandsynligt er det at kunden vil købe
          produktet
        </li>
      </ul>
      <p class="card-text">
        Du har mulighed for at vælge mellem hoved-, niche- og serviceprodukter.
        Bemærk at benchmarket ændrer sig efter produkttype
      </p>

      <p class="card-text">
        Grafen er inddelt i områder som definerer om der er et godt eller
        dårligt afsæt for lanceringen af produktet.
      </p>

      <p class="card-text">
        Magic box, øverst til højre, er det helt unikke produkt med høj
        købsvillighed. Dette er de produkter der har testet bedst.
      </p>

      <p class="card-text">
        I midterbæltet ligger produkter som har scoret godt og som vil sælge
        godt.
      </p>

      <p class="card-text">
        Nederst + til venstre viser produkter som enten ikke vil blive købt,
        eller som kunderne ikke kan skille fra andre produkter på markedet
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
};
</script>
