import Repositories from "@/data/RepositoriesFactory";
const conceptTestRepository = Repositories.get("conceptTest");

export default {
  name: "conceptTests",
  namespaced: true,
  state: () => ({
    conceptTests: [],
    conceptTestsForModel: [],
  }),
  getters: {
    getConceptTestById: (state) => (id) => {
      return state.conceptTests.find(
        (conceptTest) => conceptTest.conceptTestId == id
      );
    },
    getConceptTests: (state) => {
      return state.conceptTests;
    },
    getConceptTestsForModel: (state) => {
      return state.conceptTestsForModel;
    },
  },
  mutations: {
    setConceptTests(state, conceptTests) {
      state.conceptTests = conceptTests;
    },
    setConceptTestsForModel(state, conceptTests) {
      state.conceptTestsForModel = conceptTests;
    },
  },
  actions: {
    async fetchConceptTests({ commit }) {
      const response = await conceptTestRepository.getAll();
      if (response.status === 200) {
        commit("setConceptTests", response.data);
        return;
      }
      console.log("Failed to retrieve conceptTests");
    },
    async fetchConceptTestsForModel({ commit }) {
      const response = await conceptTestRepository.getForModel();
      if (response.status === 200) {
        commit("setConceptTestsForModel", response.data);
        return;
      }
      console.log("Failed to retrieve conceptTestsForModel");
    },
  },
};
