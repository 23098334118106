import { library } from "@fortawesome/fontawesome-svg-core";
// ToDo: When building for prod, only include actual used icons
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
// import { faUserSecret } from '@fortawesome/pro-light-svg-icons'
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(far, fas, fad, fal);

export { FontAwesomeIcon };
