import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import { FontAwesomeIcon } from "./fontawesome";

import "./style/bootstrap.scss";
import "bootstrap/dist/js/bootstrap";

import tableSort from "table-sort-js/table-sort.js";

createApp(App)
  .use(VueAxios, axios)
  .use(store)
  .provide("tableSort", tableSort)
  .component("fa", FontAwesomeIcon)
  .use(router)
  .mount("#app");
