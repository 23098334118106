<template>
  <h1>{{ proj.name }}</h1>
  <div class="row mb-4">
    <div class="col-7">
      <hr />
      <table class="table">
        <tr>
          <th>Produkt</th>
          <td>{{ proj.project }}</td>
        </tr>
        <tr>
          <th>Analyseperiode</th>
          <td>{{ proj.period }}</td>
        </tr>
        <tr>
          <th>Målgruppe</th>
          <td>{{ proj.targetGroup.description }}</td>
        </tr>
        <tr>
          <th>Målgruppestørrelse</th>
          <td>{{ dblToPercentage(proj.targetGroup.ir) }}</td>
        </tr>
        <tr>
          <th>N</th>
          <td>{{ proj.targetGroup.nTotal }}</td>
        </tr>
      </table>
      <hr />
    </div>

    <div class="col-7 mt-3" v-if="proj.potentials.length > 0">
      <h3>Potentiale</h3>
      <table class="table table-striped padding border mt-3">
        <thead>
          <tr>
            <th scope="col" class="ml-3">KPI</th>
            <th>
              TOTAL
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nTotal }}</small
              >
            </th>
            <th>
              TRYG
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nTryg }}</small
              >
            </th>
            <th>
              POTENTIELLE
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nPotentials }}</small
              >
            </th>
          </tr>
        </thead>
        <tbody v-if="proj.potentials">
          <tr v-for="kpi in proj.potentials" v-bind:key="kpi.name">
            <td>
              {{ kpi.name }}
              <div class="small" v-if="kpi.description">
                {{ kpi.description }}
              </div>
            </td>
            <!-- 
              Benchmarkfarvning:
              Vi bruger den laveste procent i BM - hvis du er over, så er du grøn
              Hvis du er inden for 5% under, så er du gul
              Under 5% => rød
            -->
            <td>
              {{ dblToPercentage(kpi.scoreTotal.value) }}
              <fa
                v-if="kpi.scoreTotal.value && kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scoreTotal.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
            <td>
              {{ dblToPercentage(kpi.scoreTryg.value) }}
              <fa
                v-if="kpi.scoreTryg.value && kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scoreTryg.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
            <td>
              {{ dblToPercentage(kpi.scorePotentials.value)
              }}<fa
                v-if="kpi.scorePotentials.value && kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scorePotentials.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-7 mt-3" v-if="proj.relevance.length > 0">
      <h3>Optimering</h3>
      <table class="table table-striped border kpi-table mt-3">
        <thead>
          <tr>
            <th class="ml-3">KPI</th>
            <th>
              TOTAL
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nTotal }}</small
              >
            </th>
            <th>
              TRYG
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nTryg }}</small
              >
            </th>
            <th>
              POTENTIELLE
              <small v-if="proj.targetGroup"
                >N: {{ proj.targetGroup.nPotentials }}</small
              >
            </th>
          </tr>
        </thead>
        <tbody v-if="proj.relevance">
          <tr v-for="kpi in proj.relevance" v-bind:key="kpi.name">
            <td>
              {{ kpi.name }}
            </td>
            <!-- 
              Benchmarkfarvning:
              Vi bruger den laveste procent i BM - hvis du er over, så er du grøn
              Hvis du er inden for 5% under, så er du gul
              Under 5% => rød
            -->
            <td>
              {{ dblToPercentage(kpi.scoreTotal.value) }}
              <fa
                v-if="kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scoreTotal.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
            <td>
              {{ dblToPercentage(kpi.scoreTryg.value) }}
              <fa
                v-if="kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scoreTryg.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
            <td>
              {{ dblToPercentage(kpi.scorePotentials.value)
              }}<fa
                v-if="kpi.benchmark"
                :icon="['fas', 'circle']"
                fixed-width
                :title="
                  'Benchmark: ' + dblToPercentage(kpi.benchmark.benchmark)
                "
                :style="{
                  color: benchmarkColor(
                    kpi.scorePotentials.value,
                    kpi.benchmark.benchmark
                  ),
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-7 mt-3">
      <h3>Omsætningspotentiale</h3>
      <p class="small i">Ved 100% kendskab</p>
      <div class="row">
        <div class="col-6">
          <h5>Pris 1</h5>
          <div style="padding: 0.8rem" class="border">
            <span v-if="proj.pricePotential1">
              {{ formatDkkValue(proj.pricePotential1) }}
            </span>
            <span class="small" v-else>Ikke beregnet for denne test</span>
          </div>
          <p class="small">
            = Målgruppestørrelse x Købsintention pris 1 x Købssandsynlighed x
            pris 1
            <!-- Bemærk: rød NOte hvis lav N -->
          </p>
        </div>
        <div class="col-6">
          <h5>Pris 2</h5>
          <div style="padding: 0.8rem" class="border">
            <span v-if="proj.pricePotential2">
              {{ formatDkkValue(proj.pricePotential2) }}
            </span>
            <span class="small" v-else>Ikke beregnet for denne test</span>
          </div>
          <p class="small">
            = Målgruppestørrelse x Købsintention pris 2 x Købssandsynlighed x
            pris 2
            <!-- Bemærk: rød NOte hvis lav N -->asd
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-7 mt-3" v-if="proj.recommendations.length > 0">
      <h3>Anbefalinger</h3>
      <div
        v-for="rec in proj.recommendations"
        v-bind:key="rec.recommendationId"
      >
        <p class="col-8 recCard small">
          {{ rec.value }}
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-7 mt-3" v-if="proj.files">
    <h3>Filer</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Navn</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="file in proj.files" v-bind:key="file.ConceptTestFileId">
          <td>
            <span v-if="file.fileType == 'Brief'" class="badge bg-primary"
              >Brief</span
            >
            <span v-if="file.fileType == 'Report'" class="badge bg-primary"
              >Rapport</span
            >
          </td>
          <td>
            {{ file.fileName }}
          </td>
          <td class="small">
            [<a
              target="_new"
              :href="
                'http://www.wilkeonline.com/diverse/tryga/' + file.fileName
              "
              >Download</a
            >]
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.concept-info {
  font-weight: bold;
}
.concept-info span {
  font-weight: normal;
}
</style>

<script>
const formatter = new Intl.NumberFormat("da-DK", {
  style: "currency",
  currency: "DKK",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default {
  name: "ConceptTest",
  props: {
    proj: Object,
  },
  methods: {
    dblToPercentage(dbl) {
      if (!dbl) return "";
      return Math.round(dbl * 100) + "%";
    },
    benchmarkColor(dblValue, dblBenchmark) {
      if (!dblValue) return "";
      if (!dblBenchmark) return "";
      if (dblValue >= dblBenchmark) return "green";
      if (dblValue >= dblBenchmark - 0.05) return "orange";
      if (dblValue < dblBenchmark) return "red";
      return "";
    },
    formatDkkValue(dblValue) {
      return formatter.format(dblValue);
    },
  },
};
</script>
