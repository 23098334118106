<template>
  <form @submit="onSubmit">
    <input type="hidden" v-model="user.id" />
    <div class="form-group">
      <label for="firstname">Fornavn</label>
      <input
        class="form-control"
        id="firstname"
        type="text"
        v-model="user.firstName"
        placeholder="Fornavn"
        required
      />
    </div>
    <div class="form-group">
      <label for="lastname">Efternavn</label>
      <input
        class="form-control"
        id="lastname"
        type="text"
        v-model="user.lastName"
        placeholder="Efternavn"
        required
      />
    </div>
    <div class="form-group mt-3">
      <label for="username">Brugernavn</label>
      <input
        class="form-control"
        id="username"
        type="email"
        v-model="user.userName"
        placeholder="Din email"
        required
      />
    </div>
    <div class="form-group mt-3">
      <label for="password">Adgangskode</label>
      <input
        class="form-control"
        id="password"
        type="password"
        v-model="user.password"
        placeholder="Skriv kode"
      />
      <p class="small">Efterlad tom, for ikke at ændre koden</p>
    </div>
    <div class="form-group mt-1">
      <label for="password2">Bekræft adgangskode</label>
      <input
        class="form-control"
        id="password2"
        type="password"
        v-model="user.confirmPassword"
        placeholder="Skriv samme kode igen"
      />
    </div>
    <div class="form-group text-danger mt-3" v-if="errorMsg">
      {{ errorMsg }}
    </div>
    <div class="form-group mt-3">
      <a @click="addOrUpdate(this.user)" class="btn btn-primary">
        <span v-if="this.user.id">Opdater bruger</span>
        <span v-else>Opret bruger</span>
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: "EditUserForm",
  data() {
    return {
      user: {
        id: "",
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        confirmPassword: "",
      },
      errorMsg: "",
    };
  },
  props: {
    userForm: Object,
  },
  watch: {
    userForm: function (newVal) {
      this.setUser(newVal);
    },
  },
  methods: {
    setUser(editUser) {
      this.user.id = editUser.id;
      this.user.firstName = editUser.firstName;
      this.user.lastName = editUser.lastName;
      this.user.userName = editUser.userName;
    },
    async addOrUpdate(user) {
      if (user.id) {
        await this.$store.dispatch("users/updateUser", this.removeEmpty(user));
      } else {
        await this.$store.dispatch("users/createUser", this.removeEmpty(user));
      }
      this.$router.push({ name: "Users" });
    },
    removeEmpty(obj) {
      return Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(obj).filter(([_, v]) => v != null && v != "")
      );
    },
    onSubmit(e) {
      e.preventDefault();
    },
  },
  created() {
    if (this.userForm) {
      this.setUser(this.userForm);
    }
  },
};
</script>
