import Repositories from "@/data/RepositoriesFactory";
const userRepository = Repositories.get("user");

export default {
  name: "users",
  namespaced: true,
  state: () => ({
    users: [],
  }),
  getters: {
    getUserById: (state) => (id) => {
      return state.users.find((user) => user.id === id);
    },
    getUsers: (state) => {
      return state.users;
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    addUser(state, user) {
      state.users.push(user);
    },
  },
  actions: {
    async fetchUsers({ commit }) {
      const response = await userRepository.getAll();
      if (response.status === 200) {
        commit("setUsers", response.data);
        return;
      }
      console.log("Failed to retrieve users");
    },
    async createUser({ commit }, user) {
      const response = await userRepository.create(user);
      if (response.status === 200) {
        //Read in id, and add to user (then add)
        console.log("Created id:");
        console.log(response.data);
        console.log(response.data.userId);
        user.id = response.data.userId;
        commit("addUser", user);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateUser({ dispatch }, user) {
      const response = await userRepository.update(user);
      if (response.status === 200) {
        await dispatch("fetchUsers");
      }
    },
    async deleteUser({ dispatch }, userId) {
      const response = await userRepository.delete(userId);
      if (response.status === 200) {
        await dispatch("fetchUsers");
      }
    },
    //delete user
    //update user
  },
};
