import Client from "@/data/AxiosClient";
import store from "@/store";
const resource = "Users";

export default {
  getAll() {
    const result = Client.get(`${resource}/GetAll`, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  get(userId) {
    const result = Client.get(`${resource}/Get?userId=` + userId, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  update(user) {
    const result = Client.post(`${resource}/Update`, user, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  create(user) {
    const result = Client.post(`${resource}/Create`, user, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  delete(userId) {
    const result = Client.delete(`${resource}/Delete?userId=` + userId, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  login(username, password) {
    const result = Client.post(
      `${resource}/Authenticate?username=` + username + `&password=` + password,
      {
        headers: {
          "content-type": "application/json",
          Authorization: "token " + store.getters["auth/getToken"],
        },
      }
    );
    return result;
  },
  loginbytoken(token) {
    const result = Client.post(
      `${resource}/AuthenticateByToken?token=` + token
    );
    return result;
  },
};
