<template>
  <h1>Rediger bruger</h1>

  <edit-user-form v-bind:userForm="this.user"></edit-user-form>
</template>

<script>
//import { useRoute } from "vue-router";
import EditUserForm from "@/components/users/EditUserForm.vue";

export default {
  name: "User",
  components: { EditUserForm },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    user() {
      if (this.userId) {
        return this.$store.getters["users/getUserById"](this.userId);
      }
      return null;
    },
  },
};
</script>
