<template>
  <conceptTest v-if="conceptTest" v-bind:proj="conceptTest"></conceptTest>
</template>

<style lang="scss" scoped>
.concept-info {
  color: #6b778c;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
}
.concept-info span {
  color: orange;
}
</style>

<script>
// @ is an alias to /src
import ConceptTest from "@/components/results/conceptTest.vue";

export default {
  name: "Results",
  components: {
    ConceptTest,
  },
  props: {
    id: String,
  },
  computed: {
    conceptTest() {
      return this.$store.getters["conceptTests/getConceptTestById"](this.id);
    },
  },
};
</script>
