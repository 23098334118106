import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";

const baseDomain = isDevelopment
  ? "https://localhost:44303/"
  : "https://trygdashboarddb.wilke.dk";
const baseURL = `${baseDomain}`;

export default axios.create({
  baseURL,
});
