<template>
  <nav class="d-none d-md-block sidebar">
    <div class="" v-if="this.isLoggedIn">
      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-4
          mb-1
        "
      >
        <span>Koncepttests</span>
      </h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link to="/" class="nav-link">
            <fa :icon="['fad', 'chart-scatter']" size="2x" fixed-width />
            Sammenligner
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/testoverview" class="nav-link">
            <fa :icon="['fal', 'list']" size="2x" fixed-width />
            Testoverblik
          </router-link>
        </li>

        <li class="nav-item" v-if="this.isAdmin">
          <router-link to="/users" class="nav-link">
            <fa :icon="['fal', 'user']" size="2x" fixed-width />
            Brugere
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
a.nav-link {
  font-size: 1.3em;
}

.sidebar {
  width: 240px;
  background-color: #6b778c;
  position: fixed;
  top: 0;
  bottom: 0;
  color: #fff;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 72px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .router-link-active {
  font-weight: 700;
}

.sidebar .nav-link {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #fff;
}

.sidebar .nav-link i {
  color: #ccc;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
};
</script>
