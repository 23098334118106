<template>
  <form @submit="onSubmit">
    <input type="hidden" v-model="user.id" />
    <div class="form-group">
      <label for="firstname">Fornavn</label>
      <input
        class="form-control"
        id="firstname"
        type="text"
        v-model="user.firstName"
        placeholder="Fornavn"
        required
      />
    </div>
    <div class="form-group">
      <label for="lastname">Efternavn</label>
      <input
        class="form-control"
        id="lastname"
        type="text"
        v-model="user.lastName"
        placeholder="Efternavn"
        required
      />
    </div>
    <div class="form-group mt-3">
      <label for="username">Brugernavn</label>
      <input
        class="form-control"
        id="username"
        type="email"
        v-model="user.userName"
        placeholder="Din email"
        required
      />
    </div>
    <div class="form-group mt-3">
      <label for="password">Adgangskode</label>
      <input
        class="form-control"
        id="password"
        type="password"
        required
        v-model="user.password"
        placeholder="Skriv kode"
      />
      <p class="small">Efterlad tom, for ikke at ændre koden</p>
    </div>
    <div class="form-group mt-1">
      <label for="password2">Bekræft adgangskode</label>
      <input
        class="form-control"
        id="password2"
        type="password"
        v-model="user.confirmPassword"
        required
        placeholder="Skriv samme kode igen"
      />
    </div>
    <div class="form-group text-danger mt-3" v-if="errorMsg">
      {{ errorMsg }}
    </div>
    <div class="form-group mt-3">
      <a @click="addOrUpdate(this.user)" class="btn btn-primary">
        Opret bruger
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: "CreateUserForm",
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        confirmPassword: "",
      },
      errorMsg: "",
    };
  },
  methods: {
    async addOrUpdate(user) {
      if (Object.values(user).some((x) => x === null || x === "")) {
        this.errorMsg = "Husk at udfylde alle felter";
        return;
      }
      await this.$store.dispatch("users/createUser", user);
      this.$router.push({ name: "Users" });
    },
    onSubmit(e) {
      e.preventDefault();
    },
  },
};
</script>
