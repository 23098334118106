import { createStore } from "vuex";
import conceptTests from "./modules/conceptTestsModule";
import auth from "./modules/authModule";
import users from "./modules/usersModule";

import createPersistedState from "vuex-persistedstate";

export default createStore({
  modules: {
    conceptTests,
    auth,
    users,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["auth"],
    }),
  ],
});
