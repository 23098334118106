<template>
  <div class="col-xl-4 col-lg-6 col-12">
    <h1>Log ind</h1>
    <form @submit="onSubmit">
      <div class="form-group">
        <label for="username">Brugernavn</label>
        <input
          class="form-control"
          id="username"
          type="email"
          v-model="form.username"
          placeholder="Din email"
          required
        />
      </div>
      <div class="form-group mt-1">
        <label for="password">Adgangskode</label>
        <input
          class="form-control"
          id="password"
          type="password"
          v-model="form.password"
          placeholder="Skriv kode"
          required
        />
      </div>
      <div class="form-group text-danger mt-3" v-if="form.errorMsg">
        {{ form.errorMsg }}
      </div>
      <div class="form-group mt-3">
        <button type="submit" class="btn btn-primary">Log ind</button>
      </div>
    </form>
  </div>
</template>

<script>
import initializer from "@/data/initOnAuth.js";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
        errorMsg: "",
      },
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      const response = await this.$store.dispatch(
        "auth/authenticate",
        this.form
      );
      if (response.status === 200) {
        this.form.errorMsg = "";
        initializer.init();
        this.$router.push({ name: "Comparer" });
      } else if (response.status === 400) {
        this.form.errorMsg = "Forkert brugernavn eller password";
      } else {
        this.form.errorMsg =
          "Kunne ikke forbinde. Prøv igen og kontakt supporten, hvis problemet fortsætter.";
      }
    },
  },
};
</script>
