import store from "@/store";

export default {
  async init() {
    if (!store.getters["auth/user"]) {
      await store.dispatch(
        "auth/fetchLoggedInUser",
        store.getters["auth/getToken"]
      );
    }
    await store.dispatch("conceptTests/fetchConceptTests");
    await store.dispatch("conceptTests/fetchConceptTestsForModel");

    const isAdmin = store.getters["auth/isAdmin"];
    if (isAdmin) {
      store.dispatch("users/fetchUsers");
    }
  },
};
