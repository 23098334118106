import Client from "@/data/AxiosClient";
import store from "@/store";
const resource = "ConceptTests";

export default {
  getAll() {
    const result = Client.get(`${resource}/GetAll`, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
  getForModel() {
    const result = Client.get(`${resource}/GetForModel`, {
      headers: {
        "content-type": "application/json",
        Authorization: "token " + store.getters["auth/getToken"],
      },
    });
    return result;
  },
};
