<template>
  <footer class="page-footer footer">
    <div class="container">
      <span class="text-muted">
        Powered by &nbsp;
        <a href="https://wilke.dk" target="_new">
          <img
            src="@/assets/wilke-logo.svg"
            style="width: 90px; padding-bottom: 5px"
          />
        </a>
      </span>
      &nbsp;&nbsp;&nbsp;
      <span class="text-muted ms-auto">
        Support:
        <a href="mailto:solutions@wilke.dk"> solutions@wilke.dk </a>
      </span>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.page-footer {
  background-color: #9ca5b2;
}
.text-muted {
  color: #fff !important;
}
.footer {
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  /* Set the fixed height of the footer here */
  height: 50px;
  line-height: 50px; /* Vertically center the text there */
  z-index: 1000;
}
</style>

<script>
export default {};
</script>
