<template>
  <div>
    <header-nav></header-nav>
    <left-menu></left-menu>
    <main>
      <router-view />
    </main>

    <footer-nav></footer-nav>
  </div>
</template>

<style lang="scss" scoped>
main {
  margin-top: 100px;
  margin-left: 280px;
  margin-right: 20px;
  margin-bottom: 0px;
}
</style>

<script>
import FooterNav from "@/components/shared/FooterNav.vue";
import HeaderNav from "@/components/shared/HeaderNav.vue";
import LeftMenu from "@/components/shared/LeftMenu.vue";

import initializer from "@/data/initOnAuth.js";

export default {
  name: "App",
  components: {
    FooterNav,
    HeaderNav,
    LeftMenu,
  },
  beforeCreate() {
    if (this.$store.getters["auth/getToken"]) {
      initializer.init();
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    isAuthorized() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  created() {},
};
</script>
