<template>
  <header>
    <nav
      class="
        navbar navbar-expand navbar-light
        fixed-top
        bg-light
        flex-md-nowrap
        shadow
      "
    >
      <a class="navbar-brand col-sm-2 col-md-1 mr-0 ml-3">
        <img src="@/assets/wilke-logo.svg" width="100" />
      </a>

      <h4 class="navbar-title mt-2 pl-3">Analyseportalen</h4>

      <div class="navbar-collapse collapse w-300 order-3 dual-collapse2">
        <div class="navbar-nav navbar-light ms-auto">
          <ul class="navbar-nav navbar-light" v-if="this.isLoggedIn">
            <li class="nav-item">
              <router-link to="/" class="nav-link text-dark"
                >FORSIDE</router-link
              >
            </li>
            <li class="nav-item">
              <a
                title="Password: Playbook2021"
                target="_new"
                class="nav-link text-dark"
                href="http://trygplaybook.wilke.dk"
                >PLAYBOOK</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark" href="mailto:solutions@wilke.dk"
                >SUPPORT</a
              >
            </li>
            <!--If signed in -->
            <div class="dropdown">
              <a
                class="btn btn-outline-dark dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="mr-3 align-middle">
                  <fa :icon="['fal', 'user-circle']" size="lg" fixed-width />
                  {{ loggedInUser.firstName }} {{ loggedInUser.lastName }}</span
                >
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuLink"
              >
                <a class="dropdown-item">
                  <i class="far fa-users fa-fw"></i> Brugere
                </a>
                <a class="dropdown-item"
                  ><i class="far fa-pencil-alt fa-fw"></i> Skift kode</a
                >
                <form asp-area="Identity" asp-page="/Account/Logout">
                  <button type="submit" class="dropdown-item">
                    <i class="far fa-fw fa-sign-out"></i> Log ud
                  </button>
                </form>
              </div>
            </div>

            <li class="nav-item">
              <a class="btn btn-link nav-link text-dark" title="Din profil">
                <i class="fa fa-fw fa-user"></i> {{ loggedInUser.email }}
              </a>
            </li>

            <li class="nav-item">
              <router-link
                :to="{ name: 'Logout' }"
                class="btn btn-link nav-link text-dark"
              >
                <i class="far fa-fw fa-sign-out"></i> Log ud
              </router-link>
            </li>

            <!--else If not signed in -->
            <!--
            <li class="nav-item">
              <a class="nav-link text-dark">Login</a>
            </li>
            -->
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.navbar-title {
  text-transform: uppercase;
}
.navbar-brand {
  margin-left: 1rem;
  font-size: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  text-align: left;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
.navbar {
  padding-right: 1rem;
}
</style>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    loggedInUser() {
      return this.$store.getters["auth/getUser"];
    },
  },
};
</script>
