import { createRouter, createWebHistory } from "vue-router";

import Comparer from "../views/Comparer.vue";
import Results from "../views/Results.vue";
import Users from "../views/Users.vue";
import CreateUser from "../views/CreateUser.vue";
import EditUser from "../views/EditUser.vue";
import TestOverview from "../views/TestOverview.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";

import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Comparer",
    component: Comparer,
  },
  {
    path: "/results/:id",
    name: "Results",
    props: true,
    component: Results,
  },
  {
    path: "/testoverview",
    name: "TestOverview",
    component: TestOverview,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      admin: true,
    },
  },
  {
    path: "/createuser",
    name: "CreateUser",
    component: CreateUser,
    meta: {
      admin: true,
    },
  },
  {
    path: "/edituser/:id",
    name: "EditUser",
    component: EditUser,
    meta: {
      admin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isAdmin = store.getters["auth/isAdmin"];
  if (!to.meta.allowAnonymous && !isAuthenticated) {
    console.log("lol");
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else if (to.meta.admin && !isAdmin) {
    next(false);
  } else {
    next();
  }
});

export default router;
